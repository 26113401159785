import { Tooltip, useColorModeValue } from '@chakra-ui/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { esES } from '@mui/x-data-grid/locales';
import { useMemo, useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { IoEyeOutline } from 'react-icons/io5';
import { formatTableValue, safeJsonParse } from 'utils/format';

export default function DataTableFermentacion({
  columns,
  rows,
  editFunction,
  seeFunction,
}) {
  const [pageSize, setPageSize] = useState(5);
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  const textPrimaryColor = useColorModeValue('#1A202C', '#FFFF');
  const textSecondaryColor = useColorModeValue('gray.700', '#FFFF');
  const mainColor = useColorModeValue('#3a3a3c', '#FFFF');
  const secondaryColor = useColorModeValue('#ff9800', '#FFFF');
  const theme = createTheme({
    palette: {
      primary: {
        main: mainColor,
      },
      secondary: {
        main: secondaryColor,
      },
      text: {
        primary: textPrimaryColor,
        secondary: textSecondaryColor,
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: 'none',
            padding: '1rem',
            boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
            borderRadius: '2rem',
            backgroundColor: balanceBg,
            fontWeight: 600,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";',
          },
          columnHeaders: {
            color: textPrimaryColor,
            backgroundColor: balanceBg,
            fontWeight: 600,
            border: 'none',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            backgroundColor: balanceBg,
            color: textPrimaryColor,
          },
        },
      },
    },
  });

  const processedRows = useMemo(() => {
    if (rows?.length > 0 && columns?.length > 0) {
      return rows.map((row) => {
        const formattedRow = {
          ...row,
          proceso: safeJsonParse(row?.proceso || {}),
          microorganismos: safeJsonParse(row?.microorganismos || {}),
          laboratorio: safeJsonParse(row?.laboratorio || {}),
          despulpadora: safeJsonParse(row?.despulpadora || {}),
        };

        columns.forEach((column) => {
          if (column?.field?.includes('.')) {
            const [category, categoryField] = column.field.split('.');
            formattedRow[column.field] = formattedRow[category][categoryField];
          } else if (column?.dataType) {
            formattedRow[column.field] = formatTableValue(
              column.dataType,
              formattedRow[column.field],
            );
          }

          if (column.field === 'registerId') {
            formattedRow[column.field] =
              formattedRow[column.field].split('.')[0];
          }
        });
        delete formattedRow.proceso;
        delete formattedRow.microorganismos;
        delete formattedRow.laboratorio;
        delete formattedRow.despulpadora;

        return formattedRow;
      });
    }
    return [];
  }, [rows, columns]);

  const processedColumns = useMemo(() => {
    return [
      {
        field: 'actions',
        headerName: 'Acciones',
        type: 'actions',
        width: 80,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip
                  label={'Editar fermentación'}
                  aria-label={'Editar fermentación'}
                  bg="black"
                  color="white"
                  padding="10px"
                  borderRadius="10px"
                >
                  <span>
                    <GoPencil color={textPrimaryColor} />
                  </span>
                </Tooltip>
              }
              label={'Editar fermentación'}
              onClick={() => editFunction(params)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip
                  label={'Ver detalle'}
                  aria-label={'Ver detalle'}
                  bg="black"
                  color="white"
                  padding="10px"
                  borderRadius="10px"
                >
                  <span>
                    <IoEyeOutline color={textPrimaryColor} />
                  </span>
                </Tooltip>
              }
              label={'Ver detalle'}
              onClick={() => seeFunction(params)}
            />,
          ];
        },
      },
      ...columns,
    ];
  }, [columns]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: '80vh', width: '100%' }}>
        <DataGridPro
          rows={processedRows}
          columns={processedColumns}
          initialState={{
            pinnedColumns: {
              left: ['id', 'registerId', 'actions'],
              right: [],
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          headerFilters
          disableDensitySelector
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            '& .MuiDataGrid-container--top [role=row]': {
              backgroundColor: balanceBg,
              color: textPrimaryColor,
            },
            '& .MuiButtonBase-root.MuiIconButton-root': {
              color: textPrimaryColor,
            },
          }}
        />
      </div>
    </ThemeProvider>
  );
}
