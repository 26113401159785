export const addFermentacion = async (TOKEN, USERID, fermentacion) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/fermentacion/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          registerId: fermentacion.registerId,
          proceso: fermentacion.proceso
            ? JSON.stringify(fermentacion.proceso)
            : null,
          laboratorio: fermentacion.laboratorio
            ? JSON.stringify(fermentacion.laboratorio)
            : null,
          microorganismos: fermentacion.microorganismos
            ? JSON.stringify(fermentacion.microorganismos)
            : null,
          despulpadora: fermentacion.despulpadora
            ? JSON.stringify(fermentacion.despulpadora)
            : null,
          createdAt: new Date(),
          createdBy: USERID,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getAllFermentacion = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/fermentacion/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 500,
            sort: { createdAt: 1 },
          },
          isCountOnly: false,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};
