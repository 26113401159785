import {
  CircularProgress,
  Flex,
  Grid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { getAllEntradas } from 'api/entradas';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import DataTableEntradas from 'components/table/entradas/dataTableEntradas';
import { ALERT } from 'constant/constant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { tableColumnsEntradas } from './variables/tableColumnsEntradas';

export default function BaseDatos() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const navigate = useNavigate();

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  const [entradasTableData, setEntradasTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  useEffect(() => {
    const fetchAllEntradas = async () => {
      try {
        setIsLoading(true);
        const response = await getAllEntradas(TOKEN);
        if (response.status === 200) {
          const result = await response.json();
          const resultData = result?.data?.data || [];
          setEntradasTableData(resultData);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        }
      } catch (error) {
        setIsLoading(false);
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      }
    };

    fetchAllEntradas();
  }, []);

  const handleSeeEntradaDetail = (params) => {
    if (params?.row) {
      const selectedEntradaById = entradasTableData.filter((x) =>
        params?.row?.registerId.includes('.0')
          ? x.entradaId === params?.row?.entradaId
          : x.id === params?.row?.id,
      );

      navigate(`/admin/seco/entradas/registro`, {
        state: {
          selectedEntrada: selectedEntradaById || params.row,
          previewMode: true,
        },
      });
    } else {
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
    }
  };

  const handleEditEntradaDetail = (params) => {
    if (params?.row) {
      const selectedEntradasById = entradasTableData.filter(
        (x) =>
          x.entradaId === params?.row?.entradaId &&
          !x.registerId.includes('.0'),
      );

      const updatedEntradas = selectedEntradasById.map((entrada) => ({
        ...entrada,
        id:
          entrada?.registerId && entrada.registerId.includes('.')
            ? Number(entrada.registerId.split('.')[1]) || 1
            : 1,
      }));

      navigate(`/admin/seco/entradas/registro`, {
        state: {
          selectedEntrada: updatedEntradas || params.row,
          previewMode: false,
          editMode: true,
        },
      });
    } else {
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
    }
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      {isLoading && (
        <Flex
          width={'100%'}
          height={'80vh'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          gap={'10px'}
        >
          <Text>Cargando...</Text>
          <CircularProgress isIndeterminate color={balanceBg} size={100} />{' '}
        </Flex>
      )}
      {!isLoading && (
        <Flex direction="column" width="stretch">
          <Grid
            templateColumns={{ base: 'repeat(2, 1fr)' }}
            gap="20px"
            display={{ base: 'block', lg: 'grid' }}
          >
            <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
              {entradasTableData && (
                <DataTableEntradas
                  rows={entradasTableData}
                  columns={tableColumnsEntradas}
                  sortModel={[{ field: 'entradaId', sort: 'desc' }]}
                  editFunction={handleEditEntradaDetail}
                  seeFunction={handleSeeEntradaDetail}
                />
              )}
            </Flex>
          </Grid>
        </Flex>
      )}
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  );
}
