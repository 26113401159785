import {
  CircularProgress,
  Flex,
  Grid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { getAllFermentacion } from 'api/fermentacion';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import DataTableFermentacion from 'components/table/fermentacion/dataTableFermentacion';
import { ALERT } from 'constant/constant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { safeJsonParse } from 'utils/format';
import { generateColumnTableData } from './variables/tableColumnsFermentacion';

export default function BaseDatos() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const navigate = useNavigate();

  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const balanceBg = useColorModeValue('brand.900', '#1B254B');

  const [fermentacionTableData, setFermentacionTableData] = useState(null);
  const [fermentacionColumnTableData, setFermentacionColumnTableData] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  useEffect(() => {
    const fetchAllFermentacion = async () => {
      try {
        setIsLoading(true);
        const response = await getAllFermentacion(TOKEN);
        const result = await response.json();
        if (response.status === 200 && result.status === 'SUCCESS') {
          const filteredFermentacionHumedo = result?.data?.data || [];
          const columnData = generateColumnTableData(
            filteredFermentacionHumedo,
          );
          setFermentacionColumnTableData(columnData);
          setFermentacionTableData(filteredFermentacionHumedo);
        }
      } catch (error) {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllFermentacion();
  }, []);

  const handleSeeFermentacionDetail = (params) => {
    if (params?.row) {
      const selectedFermentacionById = fermentacionTableData.find(
        (x) => x.id === params?.row?.id,
      );

      const fermentacionFormatted = selectedFermentacionById
        ? {
            ...selectedFermentacionById,
            entradaId: selectedFermentacionById?.registerId?.split('.')[0],
            proceso: safeJsonParse(selectedFermentacionById?.proceso || {}),
            microorganismos: safeJsonParse(
              selectedFermentacionById?.microorganismos || {},
            ),
            laboratorio: safeJsonParse(
              selectedFermentacionById?.laboratorio || {},
            ),
            despulpadora: safeJsonParse(
              selectedFermentacionById?.despulpadora || {},
            ),
          }
        : null;

      navigate(`/admin/humedo/fermentacion/registro`, {
        state: {
          selectedFermentacion: fermentacionFormatted || params.row,
        },
      });
    } else {
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
    }
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      {isLoading && (
        <Flex
          width={'100%'}
          height={'80vh'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          gap={'10px'}
        >
          <Text>Cargando...</Text>
          <CircularProgress isIndeterminate color={balanceBg} size={100} />{' '}
        </Flex>
      )}
      {!isLoading && (
        <Flex direction="column" width="stretch">
          <Grid
            templateColumns={{ base: 'repeat(2, 1fr)' }}
            gap="20px"
            display={{ base: 'block', lg: 'grid' }}
          >
            <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
              {fermentacionTableData && fermentacionColumnTableData && (
                <DataTableFermentacion
                  rows={fermentacionTableData}
                  columns={fermentacionColumnTableData}
                  editFunction={handleSeeFermentacionDetail}
                  seeFunction={handleSeeFermentacionDetail}
                />
              )}
            </Flex>
          </Grid>
        </Flex>
      )}
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  );
}
