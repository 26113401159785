import { Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import { getAllEntradasHumedo } from 'api/entradas';
import { addFermentacion, getAllFermentacion } from 'api/fermentacion';
import ResultMessage from 'components/modal/ResultMessage';
import { VSeparator } from 'components/separator/Separator';
import { useEffect, useState } from 'react';
import { IoList } from 'react-icons/io5';
import { LuActivitySquare, LuMicroscope } from 'react-icons/lu';
import { SlChemistry } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FermentacionModule from 'views/admin/humedo/fermentacion/components/FermentacionModule';
import {
  fieldsDespulpadora,
  fieldsLaboratorio,
  fieldsMicroorganismos,
  fieldsProceso,
} from 'views/admin/humedo/fermentacion/variables/fields';
import { ALERT } from '../../../../constant/constant';
import Descarga from './components/Descarga';

export default function FermentacionRegistro() {
  const TOKEN = useSelector((state) => state.auth.tokenId);
  const USERID = useSelector((state) => state.auth.userId);

  const location = useLocation();
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

  const { selectedFermentacion } = location.state || {};

  const initialFermentacionState = selectedFermentacion
    ? selectedFermentacion
    : {
        entradaId: '',
        excluidos: [],
        observaciones: '',
      };

  const [entradas, setEntradas] = useState([]);
  const [fermentaciones, setFermentaciones] = useState([]);
  const [fermentacion, setFermentacion] = useState(initialFermentacionState);
  const [refetch, setRefetch] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    state: false,
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    const fetchAllEntradas = async () => {
      try {
        setIsLoading(true);
        const filteredEntradasHumedo = await getAllEntradasHumedo(TOKEN);
        setEntradas(filteredEntradasHumedo);

        if (selectedFermentacion?.registerId) {
          const selectedEntrada = filteredEntradasHumedo.find(
            (x) => x.registerId === selectedFermentacion?.registerId,
          );

          setFermentacion((prevState) => ({
            ...prevState,
            pesoTotal: selectedEntrada.pesoNeto,
            registerId: selectedEntrada.registerId,
            entradaId: selectedEntrada.entradaId,
            productor: selectedEntrada.productor,
            estado: selectedEntrada.estado,
            region: selectedEntrada.region,
            municipio: selectedEntrada.municipio,
            comunidad: selectedEntrada.comunidad,
            cosecha: selectedEntrada.cosecha,
          }));
        }
      } catch (error) {
        console.error(error);
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE, false);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchAllFermentacion = async () => {
      try {
        setIsLoading(true);
        const response = await getAllFermentacion(TOKEN);
        const result = await response.json();
        if (response.status === 200 && result.status === 'SUCCESS') {
          const filteredFermentacionHumedo = result?.data?.data || [];
          setFermentaciones(filteredFermentacionHumedo);
        }
      } catch (error) {
        console.error(error);
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE, false);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllFermentacion();
    fetchAllEntradas();
  }, [refetch]);

  const handleChange = (category, field, value) => {
    setFermentacion((prevState) => ({
      ...prevState,
      [category]: { ...prevState[category], [field]: value },
    }));
  };

  const handleMessage = (state, msg, sub) => {
    setMessage({
      state: state,
      title: msg,
      subtitle: sub,
    });
  };

  const handleFocus = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
    setIsSubmitted(false);
  };

  const handleCloseModal = () => {
    setMessage({
      state: false,
      title: '',
      subtitle: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setIsSubmitted(true);
      const response = await addFermentacion(TOKEN, USERID, fermentacion);
      const result = await response.json();
      if (response.status === 200 && result.status === 'SUCCESS') {
        setIsSubmitted(false);
        setRefetch((prevState) => !prevState);
        handleMessage('success', ALERT.SUCCESS_TITLE, ALERT.SUCCESS_SUBTITLE);
        window.scrollTo({ top: 0, left: 0 });
      } else {
        handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
      }
    } catch (error) {
      handleMessage('error', ALERT.ERROR_TITLE, ALERT.ERROR_SUBTITLE);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Flex direction="column" width="stretch">
        <Grid
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex gridArea={{ base: '2 / 1 / 3 / 3' }}>
            <Descarga
              entradas={entradas}
              fermentacion={fermentacion}
              fermentaciones={fermentaciones}
              setFermentacion={setFermentacion}
              isSubmitted={isSubmitted}
              handleFocus={handleFocus}
            />
          </Flex>
        </Grid>
        <Grid
          mb="20px"
          gridTemplateColumns={{ base: 'repeat(2, 1fr)' }}
          gap="20px"
          display={{ base: 'block', lg: 'grid' }}
        >
          <Flex
            direction={'column'}
            gap={'20px'}
            gridArea={{ base: '2 / 1 / 3 / 3' }}
          >
            <FermentacionModule
              title={'Solicitud a laboratorio'}
              icon={SlChemistry}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleFocus={handleFocus}
              loading={isLoading}
              fields={fieldsLaboratorio}
              fermentacion={fermentacion}
            />
            <FermentacionModule
              title={'Orden de proceso'}
              icon={IoList}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleFocus={handleFocus}
              loading={isLoading}
              fields={fieldsProceso}
              fermentacion={fermentacion}
            />
            <FermentacionModule
              title={'Adición de microorganismos fermentadores'}
              icon={LuMicroscope}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleFocus={handleFocus}
              loading={isLoading}
              fields={fieldsMicroorganismos}
              fermentacion={fermentacion}
            />
            <FermentacionModule
              title={'Proceso de despulpadora'}
              icon={LuActivitySquare}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleFocus={handleFocus}
              loading={isLoading}
              fields={fieldsDespulpadora}
              fermentacion={fermentacion}
            />
          </Flex>
        </Grid>
      </Flex>
      <VSeparator
        mx="20px"
        bg={paleGray}
        display={{ base: 'none', xl: 'flex' }}
      />
      <ResultMessage
        isOpen={message.state}
        onClose={handleCloseModal}
        message={message}
      />
    </Flex>
  );
}
