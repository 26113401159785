const categories = [
  'proceso',
  'microorganismos',
  'despulpadora',
  'laboratorio',
];
const width = 200;

export const generateColumnTableData = (dataArray) => {
  const tableColumnsFermentacion = dataArray.flatMap((item) => {
    return categories.flatMap((category) => {
      // Check if item[category] is a valid JSON string before parsing
      if (!item[category]) return [];

      let parsed;
      try {
        parsed = JSON.parse(item[category]);
      } catch (error) {
        return [];
      }

      if (!parsed || Object.keys(parsed).length === 0) return [];

      return Object.keys(parsed).map((key) => ({
        headerName: `${capitalize(category)} - ${capitalize(key)}`,
        field: `${category}.${key}`,
        width,
      }));
    });
  });
  return [
    { field: 'registerId', headerName: 'Entrada ID', width: 150 },
    { field: 'createdAt', headerName: 'Fecha', dataType: 'date', width: 150 },
    ...tableColumnsFermentacion,
  ];

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};
