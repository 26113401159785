export const addSecado = async (TOKEN, USERID, secado) => {
  try {
    // Helper functions for safe entrada ids
    const getEntradaId = () => {
      const rawValue = secado?.entradaId;

      // Handle array case
      if (Array.isArray(rawValue)) {
        if (rawValue.length === 0) return 0;
        const firstElement = rawValue[0];
        return Number(firstElement) || 0;
      }

      // Handle null/undefined/empty
      if (rawValue === null || rawValue === undefined) return 0;

      // Final conversion
      return Number(rawValue) || 0;
    };

    // Helper functions for safe conversions
    const safeNumber = (value) => {
      const num = Number(value);
      return Number.isNaN(num) ? 0 : num;
    };

    const safeString = (value) => {
      if (value === null || value === undefined) return '';
      if (typeof value === 'object') return JSON.stringify(value);
      return String(value);
    };

    // Safely process nested array for excluidos
    const safeExcluidos = () => {
      try {
        const excluidos = secado.excluidos;
        if (!Array.isArray(excluidos)) return '';
        return excluidos.filter((x) => x != null).join(',');
      } catch {
        return '';
      }
    };

    // Build the request body with fallback values
    const requestBody = {
      fecha: new Date(),
      entradaId: getEntradaId(),
      folio: safeString(secado.folio),
      claveDescarga: safeString(secado.claveDescarga),
      excluidos: safeExcluidos(),
      estado: safeString(secado.estado),
      region: safeString(secado.region),
      comunidad: safeString(secado.comunidad),
      municipio: safeString(secado.municipio),
      productor: safeString(secado.productor),
      tipo: safeString(secado.tipo),
      caracteristica: safeString(secado.caracteristica),
      pergamino: safeString(secado.pergamino),
      pergaminera: safeString(secado.pergaminera),
      merma: safeString(secado.merma),
      pesoTotal: safeString(secado.pesoTotal),
      pergaminoTotal: safeString(secado.pergaminoTotal),
      pergamineraTotal: safeString(secado.pergamineraTotal),
      mermaTotal: safeString(secado.mermaTotal),
      foliosTotal: safeString(secado.foliosTotal),
      estatus: safeString(secado.estatus),
      observaciones: safeString(secado.observaciones),
      createdAt: new Date(),
      createdBy: USERID,
    };

    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/secado/create',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + TOKEN,
        },
        body: JSON.stringify(requestBody),
      },
    );

    return response;
  } catch (error) {
    console.error('Error:', error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { 'Content-Type': 'application/json' },
    });
  }
};

export const getAllSecado = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/secado/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 500,
            sort: { createdAt: 1 },
          },
          isCountOnly: false,
        }),
      },
    );
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const getLatestId = async (TOKEN) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'client/api/v1/secado/list',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          options: {
            paginate: 1,
            sort: { id: -1 },
            select: ['id'],
          },
          isCountOnly: false,
        }),
      },
    );
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const responseData = await response.json();
    const latestId =
      responseData.data?.data.length > 0 ? responseData.data?.data[0].id : 0;
    return latestId;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const asignClient = async (TOKEN, ID, client) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        'client/api/v1/secado/partial-update/' +
        ID,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          estatus: 'oferta',
          cliente: client,
        }),
      },
    );
    if (!response.ok) {
      throw new Error('Failed to update model');
    }

    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const changeStatus = async (TOKEN, ID, newStatus) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        'client/api/v1/secado/partial-update/' +
        ID,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + TOKEN,
        },
        body: JSON.stringify({
          estatus: newStatus,
        }),
      },
    );
    if (!response.ok) {
      throw new Error('Failed to update model');
    }

    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};
