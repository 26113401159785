export const extractNumericValue = (input) => {
  if (input && typeof input === 'string') {
    let formatted = input.replace(/[^0-9.]/g, '').replace(/\./g, '');
    const dotPosition = formatted.length - 1;
    let numeric = formatted;
    if (formatted.length > 2) {
      numeric =
        formatted.substring(0, dotPosition - 1) +
        '.' +
        formatted.substring(dotPosition - 1);
    }
    return numeric;
  } else if (typeof input === 'number' && !isNaN(input)) {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(input);
  } else {
    return '';
  }
};

export const displayNumericString = (input) => {
  if (input && typeof input === 'string') {
    if (input.length >= 4) {
      let numericString = input.replace(/[^0-9.]/g, '');
      let numericValue = parseFloat(numericString);
      if (!isNaN(numericValue)) {
        return new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(numericValue);
      } else {
        return '';
      }
    } else {
      return input;
    }
  } else if (typeof input === 'number' && !isNaN(input)) {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(input);
  } else {
    return '';
  }
};

export const capitalizeEveryWord = (str) => {
  if (str && typeof str === 'string') {
    return str.replace(/\b[\wáéíóúüñ]+\b/g, function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
  }
  return '';
};

export const formatTableValue = (type, value, isParent = false) => {
  if (value && value !== 'N/A') {
    switch (type) {
      case 'maquila': {
        return `M-${value}`;
      }
      case 'string': {
        return value.replace(/\b[\wáéíóúüñ]+\b/g, function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
      }
      case 'date': {
        const date = new Date(value);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
      }
      case 'numeric': {
        return value.toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      case 'kilograms': {
        const number = parseFloat(value);
        if (!isNaN(number)) {
          const localeString = number.toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          const formattedWeight = `${localeString} KG`;
          return formattedWeight;
        } else {
          return `${value} KG`;
        }
      }
      case 'grams': {
        const number = parseFloat(value);
        if (!isNaN(number)) {
          const localeString = number.toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          const formattedWeight = `${localeString} GR`;
          return formattedWeight;
        } else {
          return `${value} GR`;
        }
      }
      case 'pesos': {
        const numericValue = parseFloat(value);
        if (isNaN(numericValue)) return value;

        const formattedPesos = numericValue.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        return `${formattedPesos} MXN`;
      }
      case 'percentage': {
        if (isParent) {
          return '-';
        }
        const localeString = value.toLocaleString('en');
        const formattedPercentage = `${localeString}%`;
        return formattedPercentage;
      }
      case 'cliente': {
        return value ? value : 'Sin asignar';
      }
      default: {
        return value;
      }
    }
  } else {
    switch (type) {
      case 'cliente': {
        return 'Sin asignar';
      }
      default: {
        return '';
      }
    }
  }
};

export const getCurrentDate = () => {
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];

  const date = new Date();
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} de ${month} de ${year}`;
};

export const getLastSixMonths = () => {
  const months = [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC',
  ];
  const currentMonthIndex = new Date().getMonth();

  let result = [];
  for (let i = 5; i >= 0; i--) {
    const monthIndex = (currentMonthIndex - i + 12) % 12;
    result.push(months[monthIndex]);
  }

  return result;
};

export const getCosecha = () => {
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  return `${previousYear}-${currentYear}`;
};

export const getCosechaOptions = () => {
  const currentYear = new Date().getFullYear();
  const options = [];

  for (let i = 0; i < 5; i++) {
    const startYear = currentYear - i - 1;
    const endYear = currentYear - i;
    options.push(`${startYear}-${endYear}`);
  }

  return options;
};

export const safeJsonParse = (data) => {
  try {
    return JSON.parse(data);
  } catch {
    return {};
  }
};
