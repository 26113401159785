export const fieldsLaboratorio = [
  {
    category: 'laboratorio',
    label: 'Kilogramos Cereza',
    value: 'kilos',
    type: 'number',
    unit: 'KG',
  },
  { category: 'laboratorio', label: 'Fecha', value: 'fecha', type: 'date' },
  { category: 'laboratorio', label: 'Hora', value: 'hora', type: 'time' },
  {
    category: 'laboratorio',
    label: 'Microorganismos a preparar',
    value: 'microorganismos',
    type: 'text',
  },
  {
    category: 'laboratorio',
    label: 'Volumen para procesar',
    value: 'volumen',
    type: 'number',
    unit: 'GR',
  },
];

export const fieldsProceso = [
  { category: 'proceso', label: 'Fecha', value: 'fecha', type: 'date' },
  { category: 'proceso', label: 'Hora', value: 'hora', type: 'time' },
  {
    category: 'proceso',
    label: 'Temperatura ambiente',
    value: 'temperatura',
    type: 'text',
    unit: '°C',
  },
  {
    category: 'proceso',
    label: 'Proceso',
    value: 'proceso',
    type: 'text',
  },
  {
    category: 'proceso',
    label: 'Responsable',
    value: 'responsable',
    type: 'text',
  },
];

export const fieldsMicroorganismos = [
  {
    category: 'microorganismos',
    label: 'Kilogramos',
    value: 'kilos',
    type: 'number',
    unit: 'KG',
  },
  { category: 'microorganismos', label: 'Hora', value: 'hora', type: 'time' },
  {
    category: 'microorganismos',
    label: 'Reactor',
    value: 'reactor',
    type: 'text',
  },
  {
    category: 'microorganismos',
    label: 'Temperatura ambiente',
    value: 'temperatura',
    type: 'text',
    unit: '°C',
  },
  {
    category: 'microorganismos',
    label: 'Microorganismo (clave)',
    value: 'lote',
    type: 'text',
  },
  {
    category: 'microorganismos',
    label: 'Cantidad añadida',
    value: 'cantidad',
    type: 'number',
    unit: 'GR',
  },
  {
    category: 'microorganismos',
    label: 'Concentración >1x108',
    value: 'concentracion',
    type: 'number',
  },
  { category: 'microorganismos', label: 'pH', value: 'pH', type: 'number' },
  {
    category: 'microorganismos',
    label: '°Brix',
    value: 'brix',
    type: 'number',
  },
];

export const fieldsDespulpadora = [
  {
    category: 'despulpadora',
    label: 'Kilogramos',
    value: 'kilos',
    type: 'number',
    unit: 'KG',
  },
  { category: 'despulpadora', label: 'Hora', value: 'hora', type: 'time' },
  {
    category: 'despulpadora',
    label: 'Reactor',
    value: 'reactor',
    type: 'text',
  },
  {
    category: 'despulpadora',
    label: 'Temperatura ambiente',
    value: 'temperatura',
    type: 'text',
    unit: '°C',
  },
  {
    category: 'despulpadora',
    label: 'Resultados',
    value: 'resultados',
    type: 'textarea',
  },
  {
    category: 'despulpadora',
    label: 'Elaboro',
    value: 'elaboro',
    type: 'text',
  },
  {
    category: 'despulpadora',
    label: 'Responsable',
    value: 'responsable',
    type: 'text',
  },
  {
    category: 'despulpadora',
    label: 'Autorizo',
    value: 'autorizo',
    type: 'text',
  },
];
